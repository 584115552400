import React, { useState, useEffect } from 'react';
import { FaPencilAlt, FaRegSave, FaTrashAlt } from 'react-icons/fa';


const EditDetailsDropdown = ({ conversation, onClose, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [friendlyName, setFriendlyName] = useState(conversation.friendlyName || '');
  const [details, setDetails] = useState(conversation.attributes || {});

  const handleAddRow = () => {
    setDetails({ ...details, '': '' });
  };

  const handleChange = (key, value, oldKey = null) => {
    let newDetails = { ...details };
    if (!oldKey || oldKey !== key) {
      delete newDetails[oldKey];
    }
    newDetails[key] = value;
    setDetails(newDetails);
  };


  const handleDeleteRow = (key) => {
    const newDetails = { ...details };
    delete newDetails[key];
    setDetails(newDetails);
  };

  const handleSave = () => {
    const updatedDetails = {};
    for (let key in details) {
      if (key.trim()) {
        updatedDetails[key] = details[key];
      }
    }
    onSave(friendlyName, updatedDetails);
    setIsEditing(false);
  };

  useEffect(() => {
    setFriendlyName(conversation.friendlyName || '');
    setDetails(conversation.attributes || {});
  }, [conversation]);

  /**
    return (
      <div className="dropdown-menu">
        <div className="dropdown-header">
          <h2>Edit Details</h2>
        </div>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={friendlyName}
            onChange={(e) => setFriendlyName(e.target.value)}
            disabled={!isEditing}
          />
        </div>
        <div>
          <label>Notes</label>
          {Object.keys(details).map((key, index) => (
            <div key={index}>
              <input
                type="text"
                value={key}
                onChange={(e) => handleChange(e.target.value, details[key], key)}
                disabled={!isEditing}
              />
              <input
                type="text"
                value={details[key]}
                onChange={(e) => handleChange(key, e.target.value)}
                disabled={!isEditing}
              />
              {isEditing && <button onClick={() => handleDeleteRow(key)}>Delete</button>}
            </div>
          ))}
          {isEditing && <button onClick={handleAddRow}>Add Row</button>}
        </div>
        <div>
          {isEditing ? (
            <button onClick={handleSave}>Save</button>
          ) : (
            <button onClick={() => setIsEditing(true)}>Edit</button>
          )}
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    )
   */

  return (
    <div className="dropdown-menu">
      <div className="dropdown-header">
        <h2>Edit Details</h2>
        {isEditing ? (
        <FaRegSave className="fa edit-icon" onClick={handleSave} />
      ) : (
        <FaPencilAlt className="fa edit-icon" onClick={() => setIsEditing(true)} />
      )}
      </div>
      <div className="dropdown-content">
        <label>Name</label>
        <input
          type="text"
          value={friendlyName}
          onChange={(e) => setFriendlyName(e.target.value)}
          disabled={!isEditing}
        />
        <label>Notes</label>
        {Object.keys(details).map((key, index) => (
          <div key={index} className="dropdown-row">
            <input
              type="text"
              value={key}
              onChange={(e) => handleChange(e.target.value, details[key], key)}
              disabled={!isEditing}
            />
            <input
              type="text"
              value={details[key]}
              onChange={(e) => handleChange(key, e.target.value)}
              disabled={!isEditing}
            />
            {isEditing ? (
              <FaTrashAlt className="fa delete-icon" onClick={() => handleDeleteRow(key)} />
            ) : null}
          </div>
        ))}
        {isEditing && <button className="add-btn" onClick={handleAddRow}>Add Row</button>}
      </div>
      <div className="dropdown-actions">
        <button className="close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );

};

export default EditDetailsDropdown;
