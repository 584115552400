import React, { useEffect, useReducer, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../../contexts/AuthContext';
import ThreadsContainer from './MessagingContainers/ThreadsContainer';
import MessagesContainer from './MessagingContainers/MessagesContainer';
import LoadingIndicator from '../Utils/LoadingIndicator';
import {
  fetchAndSortConversations, fetchToken, updateLastReadMessage, setupMessageListener, createConversationDraft, addParticipant,
  parseConversationDetails, sortConversations, fetchMessages, sendMessage, setConversationStatus, deleteConversation 
} from '../Utils/MessageUtils';
import { useNotification } from '../../contexts/NotificationContext';
import { Client as ConversationsClient, Media } from '@twilio/conversations';

const initialState = {
  token: null,
  isLoadingThreads: true,
  conversations: [],
  currentConversation: null,
  messages: [],
  showMessages: false,
  allMessagesLoaded: false,
  isLoadingMessages: false,
  isSendingMessage: false,
  newMessageBody: '',
};

// State Setting Device
function reducer(state, action) {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'LOAD_CONVERSATIONS':
      return {
        ...state,
        isLoadingThreads: false,
        conversations: action.payload,
      };
    case 'DELETE_CONVERSATION':
      const updatedConvos = state.conversations.filter(convo => convo.sid !== action.payload);
      return {
        ...state,
        showMessages: false,
        currentConversation: null,
        conversations: updatedConvos,
        messages: [],
      };
    case 'UPDATE_CONVERSATION':
      const editedConvos = state.conversations.map(convo =>
        convo.sid === action.payload.sid ? action.payload : convo
      );
      return {
        ...state,
        conversations: editedConvos
      };


    case 'SET_CURRENT_CONVERSATION':
      return {
        ...state,
        currentConversation: action.payload,
        showMessages: true,
        messages: [],
        allMessagesLoaded: false,
      };
    case 'SET_SHOW_MESSAGES':
      return {
        ...state,
        showMessages: action.payload,
      };
    case 'ARCHIVE_CONVERSATION':
      return {
        ...state,
        showMessages: false,
        currentConversation: null,
        conversations: state.conversations.filter((conv) => conv.sid !== action.payload),
        messages: [],
      };
    case 'UPDATE_READ_STATUS':
      const updatedConversations = state.conversations.map((conv) => {
        if (conv.sid === action.payload.sid) {
          return {
            ...conv,
            lastReadMessageIndex: action.payload.lastReadMessageIndex,
            isUnread: action.payload.isUnread,
          };
        }
        return conv;
      });
      return {
        ...state,
        conversations: sortConversations(updatedConversations),
      };
    case 'ADD_CONVERSATION':
      const remainingOldConversations = state.conversations.filter((existingConvo) =>
        !action.payload.some((newConvo) => newConvo.sid === existingConvo.sid)
      );
      // Combine new conversations and remaining old conversations
      const newConvos = [...action.payload, ...remainingOldConversations];
      return {
        ...state,
        conversations: newConvos,
      };
    case 'LOAD_MESSAGES':
      return {
        ...state,
        messages: action.payload,
        isLoadingMessages: false,
      };
    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case 'SET_IS_MESSAGES_LOADING':
      return { ...state, isLoadingMessages: action.payload };
    case 'SET_ALL_MESSAGES_LOADED':
      return { ...state, allMessagesLoaded: true, isLoadingMessages: false };
    case 'SET_NEW_MESSAGE_BODY':
      return {
        ...state,
        newMessageBody: action.payload,
      };
    case 'START_SENDING_MESSAGE':
      return { ...state, isSendingMessage: true };
    case 'MESSAGE_RECEIVED':
      return {
        ...state,
        conversations: action.payload,
        isSendingMessage: false,
        newMessageBody: '',
      };
    case 'MESSAGE_SENT_ERROR':
      return { ...state, isSendingMessage: false };
    default:
      return state;
  }
}

const MessagingPage = () => {
  const { setNotification } = useNotification();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const containerRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [messageMenu, setMessageMenu] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const [friendlyName, setFriendlyName] = useState('');
  const [toPhone, setToPhone] = useState('');


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };



  // Ref to store the Twilio client and whether a fetch is in progress
  const clientRef = useRef(null);
  const fetchInProgressRef = useRef(new Set());
  const dropdownRef = useRef(null);
  const focusedConversationRef = useRef(null);
  const conversationsRef = useRef(state.conversations);
  const messagesRef = useRef(state.messages);

  const updateSelectedAndFocusedConversation = useCallback((updatedConversation) => {
    setSelectedConversation(updatedConversation);
    focusedConversationRef.current = updatedConversation;
  }, []);


  const handleViewEditDetails = useCallback(async () => {
    const convo = await clientRef.current.getConversationBySid(state.currentConversation.sid);
    console.log(convo);
    setIsEditingDetails(true);
    setSelectedConversation(state.currentConversation);
    setMessageMenu(false);
  }, [state.currentConversation]);

  const handleSaveDetails = useCallback(async (friendlyName, updatedDetails) => {
    if (!selectedConversation) return;

    try {
      // Update the conversation in Twilio
      const conversation = await clientRef.current.getConversationBySid(selectedConversation.sid);
      await conversation.updateFriendlyName(friendlyName)
      await conversation.updateAttributes(updatedDetails);

      // Update the conversation locally
      const updatedConversation = { ...selectedConversation, friendlyName, attributes: updatedDetails };
      const updatedConversations = conversationsRef.current.map(convo =>
        convo.sid === updatedConversation.sid ? updatedConversation : convo
      );

      conversationsRef.current = updatedConversations;

      updateSelectedAndFocusedConversation({
        ...selectedConversation,
        friendlyName,
        attributes: updatedDetails,
      });

      dispatch({ type: 'LOAD_CONVERSATIONS', payload: updatedConversations });

      setIsEditingDetails(false);
      setNotification({ type: 'success', message: 'Conversation details updated successfully.' });
    } catch (error) {
      console.error('Error updating conversation details:', error);
      setNotification({ type: 'error', message: 'Failed to update conversation details.' });
    }
  }, [selectedConversation, clientRef, dispatch, setNotification]);

  // Navigate HOME if no user
  useEffect(() => {
    if (!currentUser) {
      navigate('/');
      return;
    }
  }, [currentUser, navigate]);

  // Set Twilio Token
  const createTwilioToken = useCallback(async (accessToken) => {
    const token = await fetchToken(accessToken);
    dispatch({ type: 'SET_TOKEN', payload: token });
  }, []);

  useEffect(() => {
    const fetchTokenIfNeeded = async () => {
      if (!state.token || state.token.expiry < Date.now()) {
        await createTwilioToken(currentUser.accessToken);
      }
    };
    if (currentUser?.accessToken) fetchTokenIfNeeded();
  }, [currentUser?.accessToken, state.token, createTwilioToken]);

  useEffect(() => {
    if (state.token) {
      clientRef.current = new ConversationsClient(state.token.token);

      // Setup Listeners
      setupMessageListener(clientRef.current, onMessageAdded, onConversationAdded);

      // Cleanup listeners on unmount
      return () => {
        if (clientRef.current) {
          clientRef.current.removeAllListeners('messageAdded');
          clientRef.current.removeAllListeners('conversationAdded');
          clientRef.current.removeAllListeners('initialized');
        }
      };
    }
  }, [state.token]);

  // Refresh Twilio Client if Token Expires
  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log(state.token ? `expiry: ${state.token.expiry}. Now is ${Date.now()}` : 'no token; cannot set refresh interval.' )
      if (state.token && state.token.expiry < Date.now()) {
        createTwilioToken(currentUser.accessToken);
      }
    }, 3600000); // Check every hour

    return () => clearInterval(intervalId);
  }, [state.token, currentUser?.accessToken]);

  // Load Conversations
  const loadConversations = useCallback(async () => {
    if (fetchInProgressRef.current.has('initial')) return;
    fetchInProgressRef.current.add('initial');

    if (!state.token || state.token.expiry < Date.now() || !clientRef.current) {
      await createTwilioToken(currentUser?.accessToken);
      return;
    }

    try {
      // Fetch and sort the first 50 conversations
      const initialConvos = await fetchAndSortConversations(currentUser.accessToken, clientRef.current, true);
      dispatch({ type: 'LOAD_CONVERSATIONS', payload: initialConvos });

      // Fetch and sort the remaining conversations in the background
     fetchRemainingConversations();
    } catch (error) {
      console.error('Failed to load initial conversations:', error);
    } finally {
      fetchInProgressRef.current.delete('initial');
    }
  }, [state.token, currentUser?.accessToken, createTwilioToken]);

  const fetchRemainingConversations = async () => {
    if (fetchInProgressRef.current.has('remaining')) return;
    fetchInProgressRef.current.add('remaining');

    try {
      const allConversations = await fetchAndSortConversations(currentUser.accessToken, clientRef.current, false);
      dispatch({ type: 'LOAD_CONVERSATIONS', payload: allConversations });
    } catch (error) {
      console.error('Failed to load remaining conversations:', error);
    } finally {
      fetchInProgressRef.current.delete('remaining');
    }
  };

  // Initial Load
  useEffect(() => {
    if (state.token && state.conversations.length === 0) {
      loadConversations();
    }
  }, [state.token, loadConversations]);

  useEffect(() => {
    conversationsRef.current = state.conversations;
  }, [state.conversations]);

  // Navigate to Message Container on Conversation Select
  const navigateToMessages = useCallback((conversationSid) => {
    toggleReadStatus(conversationSid, true);
    const conversation = conversationsRef.current.find(c => c.sid === conversationSid);
    if (!conversation || (focusedConversationRef.current && conversationSid === focusedConversationRef.current.sid)) {
      return;
    }
    conversation.isUnread = false;
    focusedConversationRef.current = conversation;
    setSelectedConversation(conversation);
    dispatch({ type: 'SET_CURRENT_CONVERSATION', payload: conversation });
  }, []);


  // Navigate to Message Container on Conversation Select
  const handleCreateConversation = useCallback(async () => {
    if (!clientRef.current) {
      console.error('Refresh Twilio Client');
      return;
    }
    const conversation = await createConversationDraft(clientRef.current);
    if (!conversation) {
      console.error('Failed to create conversation draft');
      return;
    }

    focusedConversationRef.current = conversation;
    setIsDraft(true);
    setSelectedConversation(conversation);
    dispatch({ type: 'SET_CURRENT_CONVERSATION', payload: conversation });
    messagesRef.current = null;
  }, [createConversationDraft]);

  // Toggle Conversation Read Status
  const toggleReadStatus = useCallback(async (conversationSid, markRead = false) => {
    try {
      const conversation = conversationsRef.current.find(conv => conv.sid === conversationSid);
      if (!conversation) {
        return
      };
      const lastMessageIndex = conversation.lastMessage.index || 0;
      let newLastRead;
      let markUnread;
      if (!markRead && conversation.lastReadMessageIndex === lastMessageIndex) {
        // Mark as UNREAD: set lastRead to lastMessage -1
        newLastRead = lastMessageIndex > 0 ? lastMessageIndex - 1 : null;
        markUnread = true;
      } else {
        // Mark as READ: set lastRead to the last message index
        newLastRead = lastMessageIndex;
        markUnread = false;
      }

      // Update lastReadMessageIndex in Twilio
      await updateLastReadMessage(conversationSid, newLastRead, clientRef.current);

      // Update local state
      dispatch({
        type: 'UPDATE_READ_STATUS',
        payload: { sid: conversationSid, lastReadMessageIndex: newLastRead, isUnread: markUnread }
      });
    } catch (error) {
      console.error(conversationSid, 'Error toggling read status:', error);
      setNotification({ type: 'error', message: 'An Error Occurred. Could not Complete!' });
    }
  }, [state.conversations, clientRef, dispatch, setNotification]);

  // Archive Conversation
  const createTestConversation = useCallback(async (toPhone, msgBody) => {
    setMessageMenu(false);
    try {
      if (!clientRef.current) return;
       createConversationDraft(clientRef.current)
    } catch (error) {
      console.error('Error creating conversation:', error);
      setNotification({ type: 'error', message: 'An Error Occurred. Could not Delete!' });
    }
  }, [state.currentConversation, clientRef, currentUser.accessToken, dispatch, setNotification]);

  const handleDeleteConvo = useCallback(async () => {
    setMessageMenu(false);
    try{
      if (!state.currentConversation || !clientRef.current) return;
      const deleteSid = focusedConversationRef.current.sid
      deleteConversation(deleteSid, clientRef.current);
      conversationsRef.current = conversationsRef.current.filter(convo => convo.sid !== deleteSid);
      setSelectedConversation(null);
      focusedConversationRef.current = null;
      messagesRef.current = [];

      dispatch({ type: 'DELETE_CONVERSATION', payload: deleteSid })
    } catch (error) {
      console.error('Error deleting conversation:', error);
      setNotification({ type: 'error', message: 'An Error Occurred. Could not Delete!' });
    }
  }, [state.currentConversation, clientRef, currentUser.accessToken, dispatch, setNotification]);

  const handleArchiveConversation = useCallback(async () => {
    setMessageMenu(false);
    try {
      if (!state.currentConversation || !clientRef.current) return;
      const sid = state.currentConversation.sid;
      const conversation = await setConversationStatus('inactive', sid, currentUser.accessToken);
      if (conversation) {
        conversationsRef.current = conversationsRef.current.filter(convo => convo.sid !== conversation.sid);
        focusedConversationRef.current = null;
        dispatch({ type: 'ARCHIVE_CONVERSATION', payload: state.currentConversation.sid });
      }
    } catch (error) {
      console.error('Error archiving conversation:', error);
      setNotification({ type: 'error', message: 'An Error Occurred. Could not Archive!' });
    }
  }, [state.currentConversation, clientRef, currentUser.accessToken, dispatch, setNotification]);

  // Mobile: Navigate back to convos
  const backToConvos = useCallback(() => {
    dispatch({ type: 'SET_SHOW_MESSAGES', payload: false });
  }, [dispatch]);

  // Effect to mark current convo as read
  useEffect(() => {
    if (!state.currentConversation || !state.currentConversation.lastMessage?.index) return;
    const convo = state.currentConversation;
    const payload = { sid: convo.sid, lastReadMessageIndex: convo.lastMessage.index + 1, isUnread: false };
    dispatch({ type: 'UPDATE_READ_STATUS', payload });
  }, [state.currentConversation]);

  // Load Messages
  const loadMessages = useCallback(async () => {
    if (!state.currentConversation || state.allMessagesLoaded || state.isLoadingMessages) return;

    dispatch({ type: 'SET_IS_MESSAGES_LOADING', payload: true });

    try {
      let anchor = undefined;
      let switchConvo = true;
      const previousTopMessageSid = (messagesRef.current[1] && messagesRef.current[1].sid) || ''; // Store the SID of the top message

      if (focusedConversationRef.current?.sid === messagesRef.current[0]?.conversation?.sid && typeof messagesRef.current[0].index === 'number') {
        anchor = messagesRef.current[0].index;
        switchConvo = false;
      }

      if (anchor < 0) anchor = undefined;

      const newMessages = await fetchMessages(
        clientRef.current,
        state.currentConversation.sid,
        anchor,
        10
      );

      if (newMessages.length > 0) {
        // Process media URLs for each message
        const processedMessages = await Promise.all(newMessages.map(async (message) => {
          if (message.attachedMedia?.length > 0) {
         
          const mediaUrls = await Promise.all(message.attachedMedia.map(async (media) => {
              try {
                const url = await media.getContentTemporaryUrl();
                return { sid: media.sid, url }; // Use media SID for unique id
              } catch (error) {
                console.error('Error fetching media URL:', error);
                return null;
              }
            }));
          message.mediaUrls = mediaUrls.filter(urlObj => urlObj !== null); // Assign mediaUrls directly to message
        };
          return message;
        }));

        if (!switchConvo) {
          // Combine the new messages with the existing ones and filter out duplicates
          const combinedMessages = [...processedMessages, ...messagesRef.current];
          const uniqueMessages = combinedMessages.reduce((unique, message) => {
            if (!unique.some(existingMessage => existingMessage.sid === message.sid)) {
              unique.push(message);
            }
            return unique;
          }, []);
          dispatch({ type: 'LOAD_MESSAGES', payload: uniqueMessages });
          messagesRef.current = uniqueMessages;

          setTimeout(() => {
            const topMessageElement = document.querySelector(`[data-message-sid="${previousTopMessageSid}"]`);
            if (topMessageElement) {
              topMessageElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
          }, 100); // Adjust timeout as needed
        } else {
          dispatch({ type: 'LOAD_MESSAGES', payload: processedMessages });
          messagesRef.current = processedMessages;
        }
      } else {
        dispatch({ type: 'SET_ALL_MESSAGES_LOADED', payload: true });
      }
    } catch (error) {
      console.error('Failed to load messages:', error);
    } finally {
      dispatch({ type: 'SET_IS_MESSAGES_LOADING', payload: false });
    }
  }, [state.currentConversation, dispatch]);

  useEffect(() => {
    if (!state.currentConversation) return;
    loadMessages();
  }, [loadMessages, state.currentConversation]);

  // Set New Message Body
  const setNewMessageBody = useCallback((text) => {
    dispatch({
      type: 'SET_NEW_MESSAGE_BODY',
      payload: text
    });
  }, []);

  // Handle Sending Messages
  const sendMyMessage = useCallback(async () => {
    if (!state.isSendingMessage && (state.newMessageBody.trim() || selectedFile)) {

      //dispatch({ type: 'START_SENDING_MESSAGE' });
      try {
      if (isDraft) {
        const participant = await addParticipant(clientRef.current, selectedConversation.sid, toPhone, friendlyName);
        setIsDraft(false);
        setToPhone('');
        setFriendlyName('');

        const newConvo = await parseConversationDetails(selectedConversation, clientRef.current);
        setSelectedConversation(newConvo);
        focusedConversationRef.current = newConvo;
        dispatch({ type: 'UPDATE_CONVERSATION', payload: newConvo });
      }  

      const message = await sendMessage(state.currentConversation.sid, state.newMessageBody, clientRef.current, selectedFile);

      if (message) {
        messagesRef.current = [message, ...messagesRef.current];
      }

      setNewMessageBody(''); // Clear the message input
      setSelectedFile(null); // Clear the selected file after sending
      } catch (error) {
        console.error('Failed to send message:', error);
        dispatch({ type: 'MESSAGE_SENT_ERROR' });
        setNotification({ type: 'error', message: 'Failed to send message. Please try again.' });
      } finally {
        dispatch({ type: 'SET_IS_SENDING_MESSAGE', payload: false });
      }
    }
  }, [state.isSendingMessage, state.newMessageBody, state.currentConversation, clientRef, dispatch, setNotification, selectedFile, isDraft]);

  const onConversationAdded = useCallback(async (conversation) => {
    try {
      // Parse conversation details
      let newConvoDetails = await parseConversationDetails(conversation, clientRef.current);

      // Mark as unread if the last message's author is not "info@pattersonparkmusic.com"
      const lastMessage = newConvoDetails.lastMessage;
      const lastReadMessageIndex = newConvoDetails.lastReadMessageIndex;

      if (lastMessage && lastMessage.author !== 'info@pattersonparkmusic.com') {
        newConvoDetails.isUnread = true;
        newConvoDetails.lastReadMessageIndex = newConvoDetails.lastMessage.index - 1;
      }

      let updatedConversations = conversationsRef.current.map((convo) =>
        convo.sid === newConvoDetails.sid ? newConvoDetails : convo
      );

      // Check if the new conversation needs to be added
      if (!updatedConversations.some((convo) => convo.sid === newConvoDetails.sid)) {
        updatedConversations = [...updatedConversations, newConvoDetails];
      }

      // Sort the new conversations array
      const payload = await sortConversations(updatedConversations);

      // Update the conversations ref
      conversationsRef.current = payload;

      // Dispatch the new conversations array to the state
      dispatch({ type: 'ADD_CONVERSATION', payload });

      const friendlyName = newConvoDetails.friendlyName || 'a new client!';

      /**
        if (lastMessage.author !== 'info@pattersonparkmusic.com' && newConvoDetails.isUnread) {
          setNotification({
            type: 'New Message',
            message: `New Message from ${friendlyName}`,
            onClick: () => navigateToMessages(newConvoDetails.sid)
          });
        }  
      */
    } catch (error) {
      console.error('Error handling onConversationAdded:', error, conversation);
    }
  }, [clientRef, navigateToMessages, setNotification]);

  const onMessageAdded = useCallback(async (message) => {
    const focusedConversation = focusedConversationRef.current;
    let myConversations = conversationsRef.current;

    try {
      const sid = message?.conversation?.sid;
      const dateCreated = new Date(message.dateCreated);

      // Check if the message contains media
      if(message.attachedMedia?.length > 0) {
        const mediaUrls = await Promise.all(message.attachedMedia.map(async (media) => {
          try {
            const url = await media.getContentTemporaryUrl();
            return { sid: media.sid, url }; // Use media SID for unique id
          } catch (error) {
            console.error('Error fetching media URL:', error);
            return null;
          }
        }))
        message.mediaUrls = mediaUrls.filter(obj => obj !== null);
      }

      if (sid === focusedConversation?.sid) {
        let newMessages = [message];
        if (messagesRef.current) newMessages = [message, ...messagesRef.current];
        messagesRef.current = newMessages;
        dispatch({ type: 'ADD_MESSAGE', payload: message });
      } else {
        const conversationExists = myConversations.some((convo) => convo.sid === sid);
        if (!conversationExists) {
          let newConversation = await setConversationStatus('active', sid, currentUser.accessToken);
          newConversation = await parseConversationDetails(message.conversation, clientRef.current);
          myConversations = [...myConversations, newConversation];
        }

        const modifiedConversations = myConversations.map((convo) => {
          const isUnread = message.author !== 'info@pattersonparkmusic.com';
          if (convo.sid === sid) {
            return {
              ...convo,
              isUnread,
              lastMessage: { dateCreated, index: message.index },
            };
          }
          return convo;
        });

        const payload = await sortConversations(modifiedConversations);

        dispatch({
          type: 'MESSAGE_RECEIVED',
          payload
        });
      }

      if (message.author !== 'info@pattersonparkmusic.com') {
        setNotification({
          type: 'New Message',
          message: `New Message from ${message.conversation.friendlyName}`,
          onClick: () => navigateToMessages(sid)
        });
      }
    } catch (error) {
      console.error('Error handling onMessageAdded:', error, message);
      dispatch({ type: 'MESSAGE_SENT_ERROR' });
    }
  }, [clientRef, currentUser.accessToken, navigateToMessages, setNotification]);


  const handleDocumentClick = useCallback((e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setMessageMenu(false); // Close the menu if clicking outside
    }
  }, []);

  useEffect(() => {
    if (messageMenu) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [messageMenu, handleDocumentClick]);

  return (
    <div className="module messaging-container" ref={containerRef}>
      {state.isLoadingThreads ? (
        <LoadingIndicator message="Loading conversations..." />
      ) : (
        <React.Fragment>
          {/* Show threads container if not in mobile view or if messages are not shown */}
          {(!isMobile || !state.showMessages) && (
            <ThreadsContainer
              conversations={state.conversations}
              toggleReadStatus={toggleReadStatus}
              navigateToMessages={navigateToMessages}
              currentConversation={state.currentConversation}
              loadConversations={loadConversations}
              handleCreateConversation={handleCreateConversation}

            />
          )}

          {/* Show messages container if in mobile view and messages are shown, or always in large screens */}
          {(isMobile && state.showMessages) || !isMobile ? (
            <MessagesContainer
              messages={state.messages}
              fetchMessages={loadMessages}
              isLoading={state.isLoadingMessages}
              currentConversation={focusedConversationRef.current}
              isSendingMessage={state.isSending}
              sendMessage={sendMyMessage}
              setNewMessageBody={setNewMessageBody}
              newMessageBody={state.newMessageBody}
              backToConvos={backToConvos}
              isMobile={isMobile}
              messageMenu={messageMenu}
              setMessageMenu={setMessageMenu}
              dropdownRef={dropdownRef}
              handleViewEditDetails={handleViewEditDetails}
              handleSaveDetails={handleSaveDetails}
              handleArchiveConversation={handleArchiveConversation}
              handleDeleteConvo={handleDeleteConvo}
              isEditingDetails={isEditingDetails}
              setIsEditingDetails={setIsEditingDetails}
              handleFileChange={handleFileChange}
              selectedFile={selectedFile}
              createTestConversation={createTestConversation}
              clientRef={clientRef.current}
              isDraft={isDraft}
              setFriendlyName={setFriendlyName}
              setToPhone={setToPhone}
              toPhone={toPhone}
              friendlyName={friendlyName}
            />
          ) : null}
        </React.Fragment>
      )}
    </div>
  );



};

export default React.memo(MessagingPage);
