// src/components/LoadingIndicator.js

import React from 'react';

const LoadingIndicator = ({ message }) => {
  return (
    <div id="loadingSpinner" style={{ textAlign: 'center', marginTop: '20px' }}>
      <div className="spinner">
        <i className="fas fa-spinner fa-spin" style={{ fontSize: '24px' }}></i>
      </div>
      <p style={{ fontStyle: 'italic' }}>{message}</p>
    </div>
  );
};

export default LoadingIndicator;
