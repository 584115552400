// app.js

import React, { useEffect, useMemo } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import LoginPage from './components/Login/LoginPage';
import MessagingPage from './components/Messages/MessagingPage';
import { NotificationProvider } from './contexts/NotificationContext';
import NotificationDisplay from './components/Utils/NotificationDisplay';
import { useAuth } from './contexts/AuthContext';
import Header from './components/Header'; // Import the Header component
import NotificationSetup from './components/NotificationSetup'; // Import NotificationSetup


function App() {
    const { currentUser } = useAuth();

    return (
        <NotificationProvider>
            <NotificationDisplay />
            {currentUser && <NotificationSetup currentUser={currentUser} />}
            <Routes>
                {/* Route for the login page */}
                <Route path="/" element={currentUser ? <Navigate to="/messaging" /> : <LoginPage />} />

                {/* All other routes render with the header */}
                <Route element={<WithHeader />}>
                    <Route path="/messaging" element={currentUser ? <MessagingPage /> : <Navigate to="/" />} />
                    {/* Add more authenticated routes here */}
                </Route>
            </Routes>
        </NotificationProvider>
    );
}

const WithHeader = React.memo(() => {
    const { currentUser } = useAuth();

    return (
        <>
            {currentUser && <Header />}
            <Outlet />
        </>
    );
});

export default App;
