// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './FirebaseAuth';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        // Listen for auth state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                localStorage.setItem('currentUser', JSON.stringify(user)); // Persist user in localStorage
            } else {
                setCurrentUser(null);
                localStorage.removeItem('currentUser'); // Remove user from localStorage
            }
        });

        // Cleanup subscription on unmount
        return unsubscribe;
    }, []);

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setCurrentUser(userCredential.user);
            localStorage.setItem('currentUser', JSON.stringify(userCredential.user)); // Persist user in localStorage
            return userCredential.user;
        } catch (error) {
            throw error;
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
            localStorage.removeItem('currentUser'); // Remove user from localStorage
        } catch (error) {
            throw error;
        }
    };

    const value = {
        currentUser,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
