import React, { useEffect, useRef } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { useMediaQuery } from 'react-responsive';

const NotificationDisplay = () => {
    const { notification, clearNotification } = useNotification();
    const notificationRef = useRef();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            clearNotification();
        }, 4000); // Clear after 4 seconds

        return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
    }, [notification, clearNotification]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                clearNotification();
            }
        };

        const handleTouchStart = (e) => {
            notificationRef.current.touchStartY = e.touches[0].clientY;
        };

        const handleTouchMove = (e) => {
            if (!notificationRef.current) return;
            const touchEndY = e.touches[0].clientY;
            const touchStartY = notificationRef.current.touchStartY;

            if (touchStartY - touchEndY > 50) { // Swipe up detection
                clearNotification();
            }
        };

        if (isMobile) {
            if (notificationRef.current) {
                notificationRef.current.addEventListener('touchstart', handleTouchStart);
                notificationRef.current.addEventListener('touchmove', handleTouchMove);
            }
        } else {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            if (isMobile) {
                if (notificationRef.current) {
                    notificationRef.current.removeEventListener('touchstart', handleTouchStart);
                    notificationRef.current.removeEventListener('touchmove', handleTouchMove);
                }
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, [isMobile, clearNotification]);

    if (!notification) return null;  // Do not render if there is no notification

    const { type, message, onClick } = notification;

    const handleClick = () => {
        if (onClick) onClick(); // Execute custom action on click
        clearNotification(); // Dismiss notification
    };

    // Define styles based on the notification type
    const notificationStyles = {
        backgroundColor: type === 'error' ? 'red' : type === 'success' ? 'green' : 'blue',
        color: 'white',
        padding: '10px',
        cursor: 'pointer',
        position: 'fixed',    // Fix the position at the top
        top: '0',             // Align it to the top
        left: '0',            // Align it to the left
        width: '100%',        // Make it span the full width
        zIndex: 1000,         // Ensure it's above other elements
        textAlign: 'center',  // Center the text
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'  // Add a slight shadow for visual appeal
    };

    return (
        <div ref={notificationRef} onClick={handleClick} style={notificationStyles}>
            {message.toString()}
        </div>
    );
};

export default NotificationDisplay;
