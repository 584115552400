import { useEffect, useCallback, useRef } from 'react';

const VAPID_PUBLIC_KEY = 'BMCDAwSEgzFSUbvJCvAXDTDCiEW5SmxgIw9QVVBHWIQP20wKLimOtKQGkSwIuM7SfJitbS7vPvr1yb3_J-30OcA';

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const NotificationSetup = ({ currentUser }) => {

  const hasSubscribed = useRef(false);
  const serviceWorkerRegistered = useRef(false);

  const subscribeUserToPush = useCallback(async (registration) => {
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
    });

    return subscription;
  }, []);

  const registerServiceWorker = useCallback(async () => {
    if (serviceWorkerRegistered.current) {
      return;
    }

    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js');
      serviceWorkerRegistered.current = true;

      const subscription = await subscribeUserToPush(registration);

      const storedSubscription = JSON.parse(localStorage.getItem('pushSubscription'));

      if (!storedSubscription || storedSubscription.endpoint !== subscription.endpoint) {
        const subscriptionJSON = subscription.toJSON();

        const { endpoint, expirationTime, keys } = subscriptionJSON;
        const subscriptionData = {
          endpoint,
          expirationTime,
          keys,
          userId: currentUser.uid,
        };

        const response = await fetch('https://savesubscription-rqxzl3i7mq-uc.a.run.app', {
          method: 'POST',
          body: JSON.stringify(subscriptionData),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to send subscription: ${response.statusText}`);
        }

        const responseData = await response.json();

        localStorage.setItem('pushSubscription', JSON.stringify(subscriptionData));
      }

      hasSubscribed.current = true;
    } catch (error) {
      console.error('Service Worker registration failed:', error);
    }
  }, []);

  const askPermission = useCallback(async () => {
    if (hasSubscribed.current) {
      return;
    }

    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
      return;
    }

    if (Notification.permission === 'granted') {
      registerServiceWorker();
    } else if (Notification.permission === 'denied') {
    } else {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        registerServiceWorker();
      }
    }
  }, []);

  useEffect(() => {
    askPermission();
  }, []);

  return null;
};

export default NotificationSetup;
