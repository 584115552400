class Format {
  // Method to format time
  static formatTime(date, options = { hour: 'numeric', minute: 'numeric', second: undefined, hour12: true }) {
    if(!date) return;
    let timeString = date.toLocaleTimeString([], options);
    // Apply additional formatting if specified in options
      timeString = timeString.replace(/\s+/g, '').replace(/(AM|PM)/, match => match.charAt(0).toLowerCase());
    return timeString;
  }

  // Method to format date
  static formatDate(date, formatYesterday = false, options = { year: 'numeric', month: 'short', day: 'numeric' }) {

    if(formatYesterday) {
      const messageDate = new Date(date);
      const today = new Date();
      const yesterday = new Date(today.setDate(today.getDate() - 1));
      const tomorrow = new Date(today.setDate(today.getDate() + 1));

      if (messageDate.toDateString() === new Date().toDateString()) {
        return "Today";
      } else if (messageDate.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else if (messageDate.toDateString() === tomorrow.toDateString()) {
        return "Tomorrow";
      } else {
        return messageDate.toLocaleDateString();
      }
    } else {
    return date.toLocaleDateString([], options);
    }
  }

  // Method to format date-time
  static formatDateTime(date, dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }, timeOptions = { hour: 'numeric', minute: 'numeric', second: undefined, hour12: true }) {
    const dateString = date.toLocaleDateString([], dateOptions);
    const timeString = Format.formatTime(date, timeOptions);
    return `${dateString} ${timeString}`;
  }
}

export default Format;