// src/client/components/LoginPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; // Ensure the path to useAuth is correct
import { useNotification } from '../../contexts/NotificationContext'; // Ensure this is correctly imported

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth(); // Destructure login function from useAuth
    const { setNotification } = useNotification();
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await login(email, password);
            navigate('/messaging'); // Navigate to the dashboard or home page upon successful login
        } catch (error) {
            let errorMessage = 'Login failed. Please check your credentials and try again.';
            if (error.code === 'auth/wrong-password') {
                errorMessage = 'Incorrect password. Please try again.';
            } else if (error.code === 'auth/user-not-found') {
                errorMessage = 'No user found with that email address.';
            }
            setNotification({ type: 'error', message: errorMessage });
        }
    };

    return (
        <div id="login-container" className="module" style={{display: 'block'}}>
            <img src="assets/ppam_logo.png" alt="Patterson Park Academy of Music" id="logo" className="logo" />
            <form id="login-form" className="form" onSubmit={handleLogin}>
                <div className="form-group">
                    <label htmlFor="email-input" className="visually-hidden">Email</label>
                    <input
                        id="email-input"
                        type="email"
                        className="input-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password-input" className="visually-hidden">Password</label>
                    <input
                        id="password-input"
                        type="password"
                        className="input-field"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Password (or your family Door Code)"
                    />
                </div>
                <button type="submit" id="login-button" className="button">Log In</button>
                {/*<p id="password-reset" className="password-reset">Text me my Door Code/Password!</p>*/}
            </form>
        </div>
    );
}

export default LoginPage;
