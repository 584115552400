// Header.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Header({ setIsAuthenticated }) {
    const navigate = useNavigate();
    const { logout } = useAuth;

    const handleLogout = () => {
        // Perform logout logic here, e.g., clearing tokens
        logout();
        navigate('/'); // Redirect to the login page after logout
    };

    return (
        <header id="welcome-container" className="header">
            <p id="welcome-message" className="welcome-message">Welcome to the App!</p>
            {/* <nav>
                Navigation links or buttons can go here
                <button onClick={() => navigate('/messaging')}>Messaging</button>
                <button onClick={() => navigate('/schedule')}>Schedule</button>
                <button onClick={() => navigate('/payments')}>Payments</button>
                 Additional navigation links as needed 
            </nav> */}
        </header>
    );
}

export default Header;
