// ThreadsContainer.js
import React, { useState, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import { FaPlusSquare } from 'react-icons/fa';


const ThreadsContainer = ({ conversations, currentConversation, toggleReadStatus, navigateToMessages, loadConversations, handleCreateConversation }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const selectedRef = useRef();
  const filteredConversations = searchTerm 
    ? conversations.filter(convo =>
      convo.friendlyName
        ? convo.friendlyName.toLowerCase().includes(searchTerm.toLowerCase())
        : 'unnamed conversation'.includes(searchTerm.toLowerCase()))
    : conversations;
  const containerRef = useRef(null);
  const touchStartY = useRef(0);
  const isPulling = useRef(false);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

      const handleTouchStart = (e) => {
        touchStartY.current = e.touches[0].clientY;
        isPulling.current = container.scrollTop === 0;
      };

      const handleTouchMove = (e) => {
        const touchEndY = e.touches[0].clientY;
        if (isPulling.current && touchEndY > touchStartY.current + 50) {
          loadConversations();
        }
      };

      if (isTouchDevice) {
        container.addEventListener('touchstart', handleTouchStart);
        container.addEventListener('touchmove', handleTouchMove);
      }

      return () => {
        if (isTouchDevice) {
          container.removeEventListener('touchstart', handleTouchStart);
          container.removeEventListener('touchmove', handleTouchMove);
        }
      };
    }
  }, [loadConversations]);

  // Handler for clicking a specific Conversation Thread
    useEffect(() => {
      const index = conversations.findIndex(c => c.sid === currentConversation?.sid);
      if (index !== -1 && selectedRef.current) {
        setSearchTerm('');
        selectedRef.current.scrollToItem(index);  // Scroll to the selected item
      }
    }, [currentConversation, conversations]);

  // Constructor for Threads List
    const Row = ({ style, index }) => {
      const conversation = filteredConversations[index];
      const isSelected = conversation?.sid === currentConversation?.sid;
      const isUnread = conversation?.isUnread;

      if (!conversation) {
        return null; // Skip rendering if conversation is undefined
      }

      return (
        <div 
          style={style} 
          className={`conversation ${isUnread ? 'unread' : 'read'} ${isSelected ? 'selected' : ''}`}
          onClick={() => navigateToMessages(conversation.sid)} >
          <span className={`envelope-icon ${isUnread ? 'unread-icon' : 'read-icon'}`}
              onClick={(e) => {
                e.stopPropagation(); // Prevent navigateToMessages when toggling read status
                toggleReadStatus(conversation.sid);
              }}>
            <i className={isUnread ? 'fas fa-envelope' : 'far fa-envelope-open'}></i>
          </span>
          {conversation.friendlyName || 'Unnamed Conversation'}
        </div>
      );
    };

  return (
    <div className="threads-container" id="threads-container">
      <div className="threads-header">
        <h2>Conversations</h2>
        <FaPlusSquare className="fa icon" id="new-message-icon" onClick={handleCreateConversation} />
      </div>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Search conversations..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <button onClick={() => setSearchTerm('')} className="search-input-clear">
            X {/* This is a Unicode 'X' character &#x2715; */}
          </button>
        )}
      </div>
      <div className="threads-list">
        <List
          ref={selectedRef}
          height={660} // Adjust height as necessary for your layout
          itemCount={filteredConversations.length}
          itemSize={24} // Adjust the size of each item. Ensure it matches the actual height of each row
          width={300} // Adjust width as necessary for your layout
        >
          {Row}
        </List>
      </div>
    </div>
  );
};

export default ThreadsContainer;
