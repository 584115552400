import Format from "./FormatUtils";

// Group messages by date
  const groupMessages = (messages) => {
    if (!messages) return {};
    return messages.reduce((acc, message) => {
      const useYesterday = true
      const dateStr = Format.formatDate(message.dateCreated, useYesterday);
      if (!acc[dateStr]) {
        acc[dateStr] = [];
      }
      acc[dateStr].push(message);
      return acc;
    }, {});
  };

// Helper function to fetch additional details for a conversation
  const parseConversationDetails = async (convo, client) => {
    let state = '';
    let lastMessage = null;
    let lastReadMessageIndex = -1;
    let lastMessageIndex = -1;
    let attributes = {};
  
    try {
      if (!convo.lastMessage) { // Fetch additional details if necessary, esp. for Initial Fetch
        let detailedConvo = await client.getConversationBySid(convo.sid);
        lastMessage = detailedConvo.lastMessage;
        lastMessageIndex = lastMessage?.index || -1;
        lastReadMessageIndex = detailedConvo.lastReadMessageIndex || -1;
        state = detailedConvo.state?.current || 'active';
        attributes = detailedConvo.attributes || {};
      } else {    // Convo object has a last message property, e.g. fetched from fetchAllConversations
        lastMessage = convo.lastMessage
        lastMessageIndex = lastMessage?.index || -1;
        lastReadMessageIndex = convo.lastReadMessageIndex || -1;
        state = convo.state?.current || 'active';
        attributes = convo.attributes || {};
      }

      const isUnread = lastReadMessageIndex < lastMessageIndex;

      if (lastMessage) {
        lastMessage = {
          dateCreated: new Date(lastMessage?.dateCreated),
          index: lastMessage?.index
        }
      } else {
        lastMessage = {
          dateCreated: new Date(),
          index: '0'
        }
      }
      
      return {
        sid: convo.sid,
        friendlyName: convo.friendlyName,
        isUnread,
        state,
        lastReadMessageIndex,
        lastMessage,
        attributes
      };

    } catch (error) {
      console.error(`Trouble Fetching Conversation with SID: ${convo.sid}`, error);
      return null;
    }
  };

// Function to sort conversations
  const sortConversations = (conversations) => {
    try {
      return conversations
        .filter(convo => convo !== null) // && convo.state === 'active')
        .sort((a, b) => {
          // First, sort by isUnread: unread messages should come before read messages
          if (a.isUnread !== b.isUnread) {
            return b.isUnread - a.isUnread; // true (unread) comes before false (read)
          }
          // Then, sort by lastMessage.dateCreated: most recent first
          return new Date(b.lastMessage.dateCreated) - new Date(a.lastMessage.dateCreated);
        });
      } catch (error) {
        console.error('Some sorting error', error)
      }
  };

  const deleteConversation = async (sid, client) => {
    const deletedConvo = await client.getConversationBySid(sid);
    deletedConvo.delete()
  }

const createConversationDraft = async (client) => {
  try{
    const draftConvo = await client.createConversation({friendlyName: "Draft"});
    await draftConvo.add('info@pattersonparkmusic.com');
    return draftConvo;
  } catch (error) {
    console.error('Could not create conversation draft.', error);
    return null;
  }
}

const createNewConversation = async (client, accessToken, msgBody, toPhone = null) => {
  try {
    // Fetch the friendly name
    const response = await fetch('https://getfriendlyname-rqxzl3i7mq-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'MessagingBinding.Address': toPhone })
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch friendly name: ${response.statusText}`);
    }

    const friendlyName = await response.text();

    // Create a new conversation
    const newConvo = await client.createConversation({ friendlyName });
    console.log('Created Conversation:', newConvo.sid);

    // Add participants to the conversation
    const convo = await client.getConversationBySid(newConvo.sid);
    const proxyAddress = '+14104010813';
    await convo.addNonChatParticipant(proxyAddress, toPhone);
    await convo.join();
    await convo.sendMessage(msgBody);
  } catch (error) {
    console.error('Error creating conversation:', error);
  }
};

const addParticipant = async(client, conversationSid, toPhone, friendlyName) => {
  if (!toPhone) {
    console.warn('no toPhone found. Cannot add participant')
    return;
  }
  try {
    const convo = await client.getConversationBySid(conversationSid);
    await convo.updateFriendlyName(friendlyName);
    console.log(convo.friendlyName)

    const participant = await convo.addNonChatParticipant('+14104010813', '+1' + toPhone)
    console.log('added participant', participant.sid);
    return participant
  } catch(error) {
    console.error(`Could not add participant ${toPhone} to convo ${conversationSid}.`, error)
    return null;
  }
}


// Function to set the status of the current conversation
  const setConversationStatus = async (state, sid, accessToken) => {
    try {
      const response = await fetch('https://setconversationstatus-rqxzl3i7mq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ state, sid })
      });

      if (!response.ok) {
        throw new Error('Failed to set conversation status');
      }

      const data = await response.json();
      return data.conversation;
    } catch (error) {
      console.error('Error setting conversation status:', error);
      throw error;
    }
  };

// Function to fetch and sort conversations
const fetchAndSortConversations = async (accessToken, client, initial) => {
  try {
    let allConversations;
    if (initial) {
      allConversations = await fetchInitialConversations(accessToken);
    } else {
      allConversations = await fetchAllConversations(client)
    }
    
    
    // Fetch additional details and filter conversations with messages
    const filteredConversations = await Promise.all(
      allConversations.map(convo => parseConversationDetails(convo, client))
    );
    
    const sortedConversations = await sortConversations(filteredConversations);
    return sortedConversations;
  } catch (error) {
    console.error('Failed to load or process conversations:', error);
    throw error;
  }
};

// Function to update the last read message index
  const updateLastReadMessage = async (sid, newLastRead, client) => {
    try {
      const twConvo = await client.getConversationBySid(sid);
      await twConvo.updateLastReadMessageIndex(newLastRead);

      return { sid, newLastRead };
    } catch (error) {
      console.error('error updating last read message', sid);
      return;
    }
  };

// Function to setup message listener
  const setupMessageListener = (client, onMessageAdded, onConversationAdded) => {
    client.on('conversationAdded', (conversation) => {
      onConversationAdded(conversation);
    })
    client.on('messageAdded', (message) => {
      onMessageAdded(message);
    });
  };

  const updateParticipantIdentity = async (conversation, participant, identity) => {
    try {
      await conversation
        .removeParticipant(participant.sid)
        .add(identity)
      return conversation
    } catch (error) {
      console.error('Error updating participant identity:', error);
    }
  };

// Function to send a message and retrieve the full message object
const sendMessage = async (sid, messageBody, client, selectedFile = null) => {
  const conversation = await client.getConversationBySid(sid);
  let message;

  // Send text message if there is a body
  if (messageBody) {
    const textMessageIndex = await conversation.sendMessage(messageBody);
    const textMessages = await conversation.getMessages(1, textMessageIndex, 'forward');
    message = textMessages.items[0];
  }

  // Send media message if there is a selected file
  if (selectedFile) {
    const formData = new FormData();
    formData.append('media', selectedFile);

    const preparedMessage = conversation.prepareMessage();
    preparedMessage.addMedia(formData);

    const mediaMessageIndex = await preparedMessage.build().send();
    const mediaMessages = await conversation.getMessages(1, mediaMessageIndex, 'forward');
    const mediaMessage = mediaMessages.items[0];

    if (mediaMessage.attachedMedia.length > 0) {
      const mediaUrls = await Promise.all(mediaMessage.attachedMedia.map(async (media) => {
        try {
          const url = await media.getContentTemporaryUrl();
          return { sid: media.sid, url }; // Use media SID for unique id
        } catch (error) {
          console.error('Error fetching media URL:', error);
          return null;
        }
      }));
      mediaMessage.mediaUrls = mediaUrls.filter(url => url !== null);
    }

    // Combine the text and media messages
    if (!message) {
      message = mediaMessage;
    }
  }

  return message;
};


// Fetch Twilio Token
  const fetchToken = async (accessToken) => {
    const response = await fetch('https://gettwiliotoken-rqxzl3i7mq-uc.a.run.app', {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    const data = await response.json();
    return data.token;
  };

  const fetchInitialConversations = async (accessToken) => {
    const response = await fetch(`https://getactiveconversations-rqxzl3i7mq-uc.a.run.app?initial=${true}`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${accessToken}` },
    });
    const data = await response.json();
    return data.conversations;
  };  

const fetchAllConversations = async (client) => {
  let allConversations = [];
  let conversationsPaginator = await client.getSubscribedConversations();

  while (conversationsPaginator) {
    allConversations = allConversations.concat(conversationsPaginator.items);
    
    if (conversationsPaginator.hasNextPage) {
      conversationsPaginator = await conversationsPaginator.nextPage();
    } else {
      conversationsPaginator = null;
    }
  }
  return allConversations;
};

// Function to fetch messages for a conversation
  const fetchMessages = async (client, conversationSid, anchor, pageSize = 10) => {
    const conversation = await client.getConversationBySid(conversationSid);
    const newMessagesPaginator = await conversation.getMessages(pageSize, anchor, 'backwards');
    return newMessagesPaginator.items;
  };

  const getAllParticipants = async (client, conversationSid) => {
    const conversation = await client.getConversationBySid(conversationSid);
    const participants = conversation.getParticipants();
    return participants;
  }

  const getConversationDetails = async (client, conversationSid) => {
    const conversation = await client.getConversationBySid(conversationSid);
    console.log(conversation);
    return conversation;
  }


export {
  fetchAndSortConversations, updateLastReadMessage, setupMessageListener, parseConversationDetails, addParticipant, sortConversations, createConversationDraft, createNewConversation,
  groupMessages, sendMessage, fetchToken, fetchMessages, setConversationStatus, updateParticipantIdentity, deleteConversation, getAllParticipants, getConversationDetails
};