// MessagesContainer.js
import React, { useEffect, useRef, useState } from 'react';
import LoadingIndicator from '../../Utils/LoadingIndicator';
import { groupMessages, getConversationDetails } from '../../Utils/MessageUtils';
import { FaArrowLeft, FaEllipsisV, FaPaperclip } from 'react-icons/fa';
import EditDetailsDropdown from './EditDetailsDropdown'; // Import the dropdown component
import Format from '../../Utils/FormatUtils'
import InputMask from 'react-input-mask';



const MessagesContainer = ({
  messages, fetchMessages, isLoading, currentConversation, isSendingMessage, sendMessage, handleArchiveConversation, handleSaveDetails, handleFileChange, selectedFile, createTestConversation, isDraft,
  setNewMessageBody, newMessageBody, backToConvos, isMobile, messageMenu, setMessageMenu, dropdownRef, handleViewEditDetails, isEditingDetails, setIsEditingDetails, handleDeleteConvo, clientRef,
  setToPhone, setFriendlyName, toPhone, friendlyName
}) => {
  const groupedMessages = groupMessages(messages);
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const textAreaRef = useRef(null);
  const [isNewConversation, setIsNewConversation] = useState(true); // "new" meaning switched from "current conversation" view
  const topMessageRef = useRef(null);
  const [formattedPhone, setFormattedPhone] = useState('');
  const touchStartRef = useRef(null);


  useEffect(() => {
    setIsNewConversation(true); // Mark as new conversation when currentConversation changes
  }, [currentConversation]);

  useEffect(() => {
    if (isNewConversation && !isMobile) {
      const scrollToEnd = () => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
      scrollToEnd();
    }
  }, [messages, isNewConversation]);

  const growTextInput = () => {
    const textArea = textAreaRef.current;
    if (textArea.value === '') {
      textArea.style.height = ''; // Revert to default height
    } else {
      const messagesContainer = textArea.closest('.messages-container'); // Find the closest parent with the class 'messages-container'
      const height = Math.min(textArea.scrollHeight, messagesContainer.clientHeight * 0.4); // Set the height to the scroll height or max 40% of parent
      textArea.style.height = 'auto'; // Reset the height
      textArea.style.height = `${Math.max(48, height)}px`; // sets min heght to 48px
    }
  };

  const handleMsgTyping = (e) => {
    setNewMessageBody(e.target.value);
    growTextInput();
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

      const handleScroll = () => {
        if (container.scrollTop === 0 && !isLoading) {
          setIsNewConversation(false);
          fetchMessages();
        }
      };

      const handleTouchStart = (e) => {
        touchStartRef.current = e.touches[0].clientY;
      };

      const handleTouchMove = (e) => {
        const touchEnd = e.touches[0].clientY;
        if (touchStartRef.current && touchEnd > touchStartRef.current + 50 && container.scrollTop === 0 && !isLoading) {
          fetchMessages();
        }
      };

      if (isTouchDevice) {
        container.addEventListener('touchstart', handleTouchStart);
        container.addEventListener('touchmove', handleTouchMove);
      } else {
        container.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (isTouchDevice) {
          container.removeEventListener('touchstart', handleTouchStart);
          container.removeEventListener('touchmove', handleTouchMove);
        } else {
          container.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [isLoading, fetchMessages]);



  const handlePhoneChange = (e) => {
    const formattedValue = e.target.value;
    const unformattedValue = formattedValue.replace(/\D/g, ''); // Remove all non-digit characters

    setFormattedPhone(formattedValue);
    setToPhone(unformattedValue);
  };

  return (
    <div className="messages-container">
      {isLoading ? (
        <LoadingIndicator message="Loading messages..." />
      ) : (
        <React.Fragment>
          {currentConversation && (
            <div className="conversation-banner">
              {isMobile && (
                <FaArrowLeft
                  className="back-arrow"
                  onClick={backToConvos}
                />
              )}

                {isDraft ? (
                  <div className="draft-inputs">
                    <input
                      type="text"
                      value={friendlyName}
                      onChange={(e) => setFriendlyName(e.target.value)}
                      placeholder="Enter Friendly Name"
                    />
                    <InputMask
                      mask="(999) 999-9999"
                      value={toPhone}
                      onChange={(e) => handlePhoneChange(e)}
                      placeholder="(###) ###-####"
                    />
                  </div>
                ) : (
                  <div className="banner-text">
                    {currentConversation.friendlyName || 'Unnamed Conversation'}
                  </div>
                )}

              {!isEditingDetails && (
                <FaEllipsisV className="menu-icon" onClick={(e) => {
                  e.stopPropagation(); // Prevent the click event from propagating to the document
                  setMessageMenu(!messageMenu);
                }}
                />
              )}
              {messageMenu && !isEditingDetails && (
                <div className="dropdown-menu" ref={dropdownRef}>
                  <div className="dropdown-item" key="archive" onClick={handleArchiveConversation}>
                    Archive Conversation
                  </div>
                  <div className="dropdown-item" key="edit" onClick={() => handleViewEditDetails(currentConversation)}>
                    View/Edit Details
                  </div>
                  <div className='dropdown-item' key="delete" onClick={() => handleDeleteConvo(currentConversation)}>
                    Delete Convo Forever
                  </div>
                    <div className='dropdown-item' key='create' onClick={() => getConversationDetails(clientRef, currentConversation.sid) }>
                    Log Conversation
                  </div>
                </div>
              )}
              {isEditingDetails && (
                <EditDetailsDropdown
                  conversation={currentConversation}
                  onClose={() => setIsEditingDetails(false)}
                  onSave={handleSaveDetails}
                  client={clientRef}
                />
              )}
            </div>
          )}
          <div ref={containerRef} className="messages-content">
            {isLoading && <LoadingIndicator message="Loading more messages..." />}
            {currentConversation === null ? (
              <p>Select a conversation to view messages</p>
            ) : (Object.keys(groupedMessages).length > 0 ? (
              Object.entries(groupedMessages).map(([date, messagesOfDay]) => (
                <div key={date} className="date-section">
                  <div className="date-separator">{date}</div>
                  {Array.isArray(messagesOfDay) && messagesOfDay.map((message, index) => (
                    <div
                      key={message.sid}
                      className={`${message.author === 'info@pattersonparkmusic.com' ? 'message-sent-container' : 'message-received-container'}`}
                      data-message-sid={message.sid}
                      ref={index === 0 ? topMessageRef : null} // Add ref to the first message
                    >
                      <div className="message-timestamp">
                        {Format.formatTime(message.dateCreated)}
                      </div>
                      <div className={`message-body ${message.author === 'info@pattersonparkmusic.com' ? 'message-sent' : 'message-received'}`}>
                        {message.body}
                        {message.mediaUrls?.length > 0 && (
                          <div className="message-media">
                            {message.mediaUrls.map(({sid, url}) => (
                              <img key={sid} src={url} alt={`media-${sid}`} className="message-image" />
                            ))}
                          </div>
                        )}
                      </div>
                      {/* {message.author !== 'info@pattersonparkmusic.com' && (
                        <div className="message-author">
                          {message.conversation.friendlyName}
                        </div>
                      )} */}
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>No Messages to Load!</p>
            ))}
            <div ref={messagesEndRef} />
          </div>
          {currentConversation && (
            <div className="new-message-area">
              <textarea
                ref={textAreaRef}
                className="new-message-input"
                placeholder="Type your message here..."
                value={newMessageBody}
                onChange={handleMsgTyping}
                disabled={isLoading}
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="file-input"
                id="attach-file"
              />
                <label htmlFor="attach-file" className="file-input-label">
                  <FaPaperclip className="file-input-icon fa" />
                </label>
              <button className="send-message-button"
                disabled={isSendingMessage || newMessageBody.trim() === '' && !selectedFile}
                onClick={sendMessage}>
                <i className="fas fa-circle-arrow-up"></i>
              </button>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default MessagesContainer;
