// firebase.js in bubdle

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWSalLI7XlS4k-HeP5eWJAf-nVHjD-860",
  authDomain: "ppam-messaging-app.firebaseapp.com",
  projectId: "ppam-messaging-app",
  storageBucket: "ppam-messaging-app.appspot.com",
  messagingSenderId: "21912867791",
  appId: "1:21912867791:web:59e0b0bf67d76f2222b8ba",
  measurementId: "G-25ZTSBW76X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth };
export { app };