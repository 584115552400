// NotificationContext.js

import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);

    const clearNotification = () => setNotification(null);

    return (
        <NotificationContext.Provider value={{ notification, setNotification, clearNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};
