// ./src/client/public/src.js/index.js

// import React for client-routing
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './client/components/Utils/ErrorBoundary.js';
import { AuthProvider } from './client/contexts/AuthContext.js';
import App from './client/app.js'; // Assuming this is your main React component
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <AuthProvider>
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </AuthProvider>
 // </React.StrictMode>
);

serviceWorkerRegistration.register();